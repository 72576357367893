import * as React from 'react'
import PropTypes from 'prop-types'

// Portfolio Cards Grid Component
const Grid = ({ children }) => {
	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-40 sm:gap-60 lg:gap-80 xl:gap-100 xxl:gap-120">
			{children}
		</div>
	)
}

Grid.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Grid
