import * as React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

import Gif from './gif'

// Gatsby Image Component for WEBP/AVIF Conversion
const Image = ({ asset }) => {
	switch (asset?.ext) {
		case '.png':
		case '.PNG':
		case '.jpg':
		case '.JPG':
		case '.jpeg':
		case '.JPEG':
			return (
				<div className="grid text-0">
					<GatsbyImage image={getImage(asset.imageFile)} alt={asset.name} />
				</div>
			)

		// Don't Use Gatsby Image for GIFs (Unsupported)
		case '.gif':
		case '.GIF':
			return <Gif image={asset} />

		default:
			return (
				<p className="text-red">
					Invalid or Missing Image. Please Upload a PNG/JPG/JPEG or a GIF.
				</p>
			)
	}
}

Image.propTypes = {
	asset: PropTypes.shape({
		ext: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		imageFile: PropTypes.shape({}).isRequired,
	}).isRequired,
}

export default Image
