import * as React from 'react'
import PropTypes from 'prop-types'

// External Link Component
const ExternalLink = ({ link, children }) => (
	<a
		href={link}
		rel="noreferrer"
		target="_blank"
		className="underline hover:text-blue"
	>
		{children}
	</a>
)

ExternalLink.propTypes = {
	link: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

export default ExternalLink
