import * as React from 'react'
import PropTypes from 'prop-types'

// Kept as a fallback: Avoid using too many large GIFs
const Gif = ({ image }) => {
	const padding = (image.height / image.width) * 100

	return (
		<div
			className="relative bg-placeholder"
			// Padding allows you to control the visible region
			style={{ paddingTop: padding + '%' }}
		>
			<img
				className="absolute top-0 left-0 w-full h-full object-cover object-center"
				loading="lazy"
				src={image.imageFile.publicURL}
				alt={image.name}
			/>
		</div>
	)
}

Gif.propTypes = {
	image: PropTypes.shape({
		name: PropTypes.string.isRequired,
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
		imageFile: PropTypes.shape({
			publicURL: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
}

export default Gif
