import * as React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/layout'
import Actions from '../components/actions'

import Grid from '../components/partials/grid'
import Card from '../components/partials/card'

const NotFoundPage = ({ data }) => {
	const contact = data.strapi.contactPage

	contact.metadata.meta_title =
		'404 - Page Not Found | Branding & UX Design Studio'

	return (
		<Layout page={contact} metadata={contact.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-20 lg:py-40">
					<h1 className="w-10/12">
						Looks like you're lost. Since you're here, why not check out our
						work?
					</h1>
				</section>

				{/* Portfolio Cards */}
				<section className="py-40 sm:py-60 md:py-80 lg:py-100 xl:py-120">
					<Grid>
						{contact.projects.map(({ id, project }) => (
							<div key={`project_card_small_${id}`}>
								<Card project={project} />
							</div>
						))}
					</Grid>

					<div className="pt-60 lg:pt-80 xxl:pt-120 text-center">
						<Link className="hover:text-blue underline" to="/portfolio">
							<h2 className="h1">See all projects</h2>
						</Link>
					</div>
				</section>

				{/* Action Cards */}
				<section className="py-40 lg:py-80 xl:py-100 xxl:py-140">
					<Actions />
				</section>
			</div>
		</Layout>
	)
}

NotFoundPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			contactPage: PropTypes.shape({
				headline: PropTypes.string.isRequired,
				projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	{
		strapi {
			contactPage {
				headline
				projects {
					id
					project {
						slug
						title
						subtitle
						thumbnail {
							url
							ext
							height
							width
							name
							imageFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										width: 360
										height: 270
										transformOptions: { cropFocus: CENTER, fit: COVER }
										layout: CONSTRAINED
										formats: [AUTO, WEBP, AVIF]
									)
								}
							}
						}
					}
				}
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
		}
	}
`

export default NotFoundPage
