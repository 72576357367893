import * as React from 'react'
import PropTypes from 'prop-types'

// Hue Effect Card
const HueCard = ({ children }) => (
	<div className="group bg-white bg-600 lg:hover:bg-hue lg:animate-hue p-20 sm:p-30 xxl:p-40 h-full">
		{children}
	</div>
)

HueCard.propTypes = {
	children: PropTypes.node.isRequired,
}

export default HueCard
