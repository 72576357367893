import * as React from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

// import Instagram from './partials/instagram'
import ExternalLink from './partials/external'
import HueCard from './partials/hue'
import InstagramGif from '../assets/opposite-instagram.gif'

const Actions = () => {
	// const data = useStaticQuery(graphql`
	// 	{
	// 		allInstaNode {
	// 			edges {
	// 				node {
	// 					id
	// 					imageFile: localFile {
	// 						url
	// 						childImageSharp {
	// 							gatsbyImageData(
	// 								width: 360
	// 								height: 360
	// 								transformOptions: { cropFocus: CENTER, fit: COVER }
	// 								layout: CONSTRAINED
	// 								formats: [AUTO, WEBP, AVIF]
	// 							)
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// `)

	// const instagram = data.allInstaNode.edges

	const [modalState, toggleModal] = useState(false)

	return (
		<div className="grid grid-cols-1 lg:grid-cols-3 auto-rows-fr gap-20 lg:gap-30">
			<Link to="/careers">
				<StandardCard title="Join us at Opposite" subtitle="See Openings →" />
			</Link>

			<ExternalLink link="https://instagram.com/opposite.hq">
				<HueCard>
					<div className="grid grid-cols-2 sm:grid-cols-3 lg:flex lg:flex-col h-full justify-between">
						<div className="text-0">
							<img src={InstagramGif} alt="Opposite Instagram" loading="lazy" />
						</div>
						<h3 className="sm:h2 lg:h3 col-start-2 col-span-2 sm:pr-60 lg:pr-0 self-end lg:self-start justify-self-end lg:pt-40 xxl:pt-80 group-hover:text-blue">
							@opposite.hq
						</h3>
					</div>
				</HueCard>
			</ExternalLink>

			<button
				className="text-left focus:outline-none"
				onClick={() => toggleModal(!modalState)}
			>
				<StandardCard
					title="Keep up with what we've been up to"
					subtitle="Sign Up →"
				/>
			</button>

			<Substack toggle={toggleModal} active={modalState} />
		</div>
	)
}

// Standard Card
const StandardCard = ({ title, subtitle }) => (
	<HueCard>
		<div className="flex flex-col h-full justify-between">
			<h3 className="h1 sm:w-4/6 lg:w-full lg:text-42 xl:text-56 xxl:text-64">
				{title}
			</h3>
			<h3 className="h2 text-20 sm:text-24 md:text-32 lg:text-30 xl:text-36 xxl:text-42 pt-10 lg:pt-40 xxl:pt-80 group-hover:text-blue">
				{subtitle}
			</h3>
		</div>
	</HueCard>
)

StandardCard.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
}

// Substack Embed
const Substack = ({ toggle, active }) => (
	<div
		className={`flex fixed z-40 left-0 top-0 bg-slate w-full h-screen ${
			active ? 'block' : 'hidden'
		}`}
	>
		<div className="container relative m-auto bg-default ">
			<iframe
				src="https://oppositehq.substack.com/embed"
				title="Opposite Substack"
				className="mx-auto h-600"
				width="100%"
				frameBorder="1"
				scrolling="no"
			/>
			<CloseButton toggle={toggle} active={active} />
		</div>
	</div>
)

Substack.propTypes = {
	toggle: PropTypes.func.isRequired,
	active: PropTypes.bool.isRequired,
}

// Close Button Component
const CloseButton = ({ toggle, active }) => (
	<button
		onClick={() => toggle(!active)}
		className="absolute top-30 right-30 p-15 rounded-full focus:outline-none bg-white z-30 shadow-md"
	>
		<svg width="20" height="20" viewBox="0 0 20 20">
			<path
				fill="transparent"
				strokeWidth="2"
				stroke="#333333"
				d="M 2 18 L 18 2"
			/>
			<path
				fill="transparent"
				strokeWidth="2"
				stroke="#333333"
				d="M 2 2 L 18 18"
			/>
		</svg>
	</button>
)

export default Actions
